html {
  color: #afc8ad;
  height: 100%;
}
body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-track {
  background-color: #eee7da;
}

::-webkit-scrollbar-thumb {
  background-color: #ddd3be;
  border-radius: 6px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #a19684;
}

* {
  scrollbar-color: #eee7da;
}

* {
  scrollbar-color: #ddd3be;
}

*::-moz-scrollbar-thumb {
  background-color: #eee7da;
}

*::-moz-scrollbar-thumb:hover {
  background-color: #bfb29d;
}
